import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      You just found a lot of{" "}
      <span role="img" aria-label="watermelons">
        🍉
      </span>{" "}
      !!
    </p>
  </Layout>
)

export default NotFoundPage
